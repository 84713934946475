import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"

import dailydealsimage from "../images/marketing/svg/daily-deals/daily-deals-hero.svg"
import bonitalogoimg from "../images/marketing/svg/daily-deals/bonita-logo-2x.png"
import dtrlogo from "../images/marketing/svg/daily-deals/DTR-logo-2x.png"
import capitallogo from "../images/marketing/svg/daily-deals/capital-logo-2x.png"
import pharmsource from "../images/marketing/svg/daily-deals/pharmsource-2x.png"
import rivercitylogo from "../images/marketing/svg/daily-deals/river-city-logo-2x.png"
import infographicsimg from "../images/marketing/svg/daily-deals/infographics-illustration.svg"
import clockimg from "../images/marketing/svg/daily-deals/clock-illustration.svg"
import databaseimg from "../images/marketing/svg/daily-deals/database-illustration.svg"

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>EzriRx.com - Daily Deals On Our Pharmacy Platform</title>
      <meta
        name="keywords"
        content="pharmacy portal, pharmacy purchasing platform, ezrirx"
      />
      <meta
        name="description"
        content="EzriRx daily deals allows pharmacies to save big on drug prices offered by many suppliers. Bookmark, browse, compare, and save an average of 30% on all drugs."
      />
      <link rel="canonical" href="https://www.ezrirx.com/daily-rx-discounts/" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="hero-banner" id="hero-daily-deals">
          <div className="contents">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>
                  New deals. Every day.
                  <br />
                  Never miss incredible savings.
                </h1>
                <p>
                  Each day a curated list that is tailored to your pharmacy
                  purchasing needs. It's unique, it's fresh and has some amazing
                  savings.
                </p>
                <a
                  className="button with-border move-to-section"
                  href="#ez-marketing-subscribe"
                >
                  Subscribe now
                </a>
              </div>
              <div className="hero-image">
                <img
                  src={dailydealsimage}
                  alt="New deals. Every day. Never miss incredible savings on Pharmaceuticals."
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content">
          <div className="contents">
            <div className="table-2cols table-2cols--mobile">
              <div className="col">
                <div className="daily-deals__logos">
                  <img
                    src={bonitalogoimg}
                    alt="Bonita"
                    className="daily-deals__logo daily-deals__logo1"
                  ></img>
                  <img
                    src={dtrlogo}
                    alt="DTR"
                    className="daily-deals__logo daily-deals__logo2"
                  ></img>
                  <img
                    src={capitallogo}
                    alt="Capital"
                    className="daily-deals__logo daily-deals__logo3"
                  ></img>
                  <img
                    src={pharmsource}
                    alt="Pharmsource"
                    className="daily-deals__logo daily-deals__logo4"
                  ></img>
                  <img
                    src={rivercitylogo}
                    alt="River City"
                    className="daily-deals__logo daily-deals__logo5"
                  ></img>
                </div>
              </div>
              <div className="col">
                <div className="title-box">
                  <h2>
                    There are over 50 Brand, Generic &amp; OTC deals each day
                    from our wholesalers.
                  </h2>
                </div>
                <p>
                  As you begin purchasing, EzriRx will be curating unique deals
                  which are relevant to your pharmacy. Each morning, you will
                  receive a special list emailed to your inbox with good deals,
                  price changes, short dates and products that are going into
                  shortage.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-border">
          <div className="contents">
            <div className="table-2cols align-top">
              <div className="col col--text">
                <div className="title-box">
                  <h2>Combined purchasing power brings better deals.</h2>
                </div>
                <p>
                  A collective of pharmacies acting together brings more
                  wholesalers to the table with deeper discounts on every day
                  purchases. We act in numbers.
                </p>
              </div>
              <div className="col col--img text-center">
                <img
                  src={infographicsimg}
                  alt="Combined purchasing power, brings better deals for pharmacies."
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-border">
          <div className="contents">
            <div className="table-2cols align-top table-2cols--mobile">
              <div className="col">
                <img
                  src={clockimg}
                  alt="Limited time offers on pharmaceuticals."
                  className="image-shadow"
                ></img>
              </div>
              <div className="col">
                <div className="title-box">
                  <h2>
                    Limited time offers. <span className="status">New</span>
                  </h2>
                </div>
                <p>
                  When great deals are unleashed, quantities become scarce and
                  time constraints make it hard to keep up. We track those
                  limited time offers and have them ready for purchase when you
                  are.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-utility" id="ez-marketing-subscribe">
          <div className="contents">
            <div className="marketing-utility__subscribe">
              <div className="title-box">
                <h2>Subscribe to our daily deals</h2>
                <p>
                  Receive a fresh list of amazing deals delivered to your inbox
                  each morning.
                </p>
              </div>

              <div className="subscribe-form">
                <form
                  action="//ezrirx.us14.list-manage.com/subscribe/post?u=e7a2cb05150fe0d86fc383e50&amp;id=acfab17a28"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  noValidate
                >
                  <div className="mc-field-group">
                    <input
                      type="email"
                      name="EMAIL"
                      className="form-control required email"
                      id="mce-EMAIL"
                      placeholder="Enter your email"
                      aria-label="Email"
                    />
                  </div>
                  <input
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button"
                  />
                </form>
              </div>
              <img src={databaseimg} alt="Subscribe for our deals"></img>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
